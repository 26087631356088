import { useState, useRef } from "react"
import Lottie from "lottie-react"
import { format } from 'date-fns'
import { Pagination, FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ReactComponent as Crown } from '../../assets/svgs/crown.svg';
import { lsDecode, lsEncode } from '../../utils/localStorage'
import lutins from "../../assets/lotties/lutins.json"
import data from "../../data.json"
import SliderStyle from './style'
import 'swiper/css';

const SPEED = 500

const Slider = ({ setPopin, setSlide, reducedMotion }) => {
    const desktop = window.innerWidth > 1023
    const tablet = window.innerWidth > 767
    const swiperRef = useRef(null)
    const [visited, setVisited] = useState(lsDecode('sevalendar_visited') ? JSON.parse( lsDecode('sevalendar_visited') ) : []);

    const today = new Date()
    // const today = new Date(new Date().getFullYear(), 11, 28)
    const todayMonth = 12;
    const todayDay = 25;

    if ( todayMonth !== 12 ) return (
        <p className="notice">
            Attendez le mois de décembre<br />pour découvrir le calendrier !
        </p>
    )

    const slideClicked = (index) => {
        setSlide(index)

        if ( swiperRef.current.swiper.activeIndex !== index ) {
            swiperRef.current.swiper.slideTo(index)
            setTimeout(() => setPopin(true), SPEED)
        } else {
            setPopin(true)
        }
    }

    return (
        <SliderStyle>
            <Swiper
                ref={swiperRef}
                modules={[Pagination, FreeMode]}
                slidesPerView={desktop ? 3 : tablet ? 2 : 1}
                speed={SPEED}
                spaceBetween={20}
                initialSlide={todayDay}
                pagination={{ clickable: true }}
            >
                { [...Array(todayDay).keys()].map((item) => (
                    <SwiperSlide
                        key={ item }
                        className={`christmas-card ${visited?.includes(item) ? 'is-visited' : ''} `}
                        onClick={() => {
                            slideClicked(item)
                            setVisited([...visited, item])
                            lsEncode('sevalendar_visited', `[${[...visited, item]}]`)
                        }}
                    >
                        <div className="inner">
                            <div className="crown">
                                <Crown />
                            </div>

                            <p>
                                { data?.[item]?.title }
                            </p>
                        </div>

                        <div className="lighting"></div>
                    </SwiperSlide>
                ))}

                { todayDay < 24 ? (
                    <SwiperSlide className="soon">
                        <Lottie className="lutins" animationData={lutins} loop={reducedMotion} />
                    </SwiperSlide>
                ) : tablet ? <SwiperSlide></SwiperSlide> : '' }

                { desktop ? <SwiperSlide></SwiperSlide> : '' }
            </Swiper>
        </SliderStyle>
    )
}

export default Slider
